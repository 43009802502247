<template>
  <!-- <a :href="getLink" target="_blank" class="px-6 py-3 text-white-text text-center no-underline bg-blue-600 rounded hover:bg-blue-600 hover:text-blue-200">
        {{getLable}}
    </a> -->
  <div>
    <button
      class="px-6 py-3 text-white-text text-center no-underline bg-primary rounded hover:bg-primary-600 hover:text-white-text-600"
      @click="checkLink"
    >
      {{getLable}}
    </button>
    <ModalContent
      ref="modalContent"
      v-bind="modalContentData.config"
    >
      <template #content>
        {{ modalContentData.message }}
      </template>
    </ModalContent>
    <ModalConfirm
      ref="modalConfirm"
      v-bind="modalConfirmData.config"
    />
  </div>
</template>

<script>
import axios from "@/axios";
import ModalContent from "@shared/modal-content";
import ModalConfirm from "@shared/modal-confirm";

export default {
  props: {
    fieldData: {
      type: Object,
      require: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ModalContent,
    ModalConfirm,
  },
  data() {
    return {
      // response: null,
      modalContentData: {
        message: '',
        config: {
          showHeader: false,
          showFooter: false,
        }
      },
      modalConfirmData: {
        config: {
          showHeader: false,
        }
      },
    }
  },
  computed: {
    getLable() {
      return this.fieldData.label || "click to follow link";
    },
    getLink() {
      return this.fieldData.value || "#";
      // return JSON.parse(this.fieldData.meta)?.url || "#"
      // return this.fieldData.url || "#"
    },
  },
  methods: {
    async checkLink() {
      try {
        const {data: response} = await axios.get(this.getLink);
        // this.response = response;
        const {code, message, link} = response;

        let routeData, promise;
        if (link) {
          if (link.startsWith("http://") || link.startsWith("https://")) {
            routeData = {href: link}
          } else {
            routeData = this.$router.resolve({path: link});
          }
        }
        const windowConf = 'location=yes,height=570,width=520,scrollbars=yes,status=yes';

        switch (code) {
          case 'USER_INFO_POPUP_MOVE_SECTION_NEXT':
            promise = await this.$refs.modalConfirm.show({
              message,
              buttonText: 'Next',
            })
            if (promise) this.$emit('nextSection');
            break;
          case 'USER_INFO_POPUP_KEEP_SECTION':
            this.modalContentData.message = message;
            this.$refs.modalContent.showModal();
            break;
          case 'REDIRECT_USER_SAME_WINDOW':
            this.$router.push(link);
            break;
          case 'REDIRECT_USER_NEW_TAB':
            window.open(routeData.href, '_blank');
            break;
          case 'REDIRECT_USER_NEW_WINDOW':
            window.open(routeData.href, '_blank', windowConf);
            break;
          case 'MOVE_NEXT_SECTION':
            this.$emit('nextSection');
            break;
        
          default:
            break;
        }
      } catch (error) {
        console.log('Linked Button error', error);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>